<template>
  <div>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addKernetSidebar = true"
            >
              + Tambah Data Kernet
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="data.value === 'aktif' ? 'light-success' : 'light-danger'"
          >
            {{ data.value === 'aktif' ? 'Aktif' : 'Tidak Aktif' }}
          </b-badge>
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`kernet-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editKernet(data.item, true)"
              >
                Edit
              </b-button>
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`kernet-row-${data.item.id}-edit-icon`"
              />

              <b-button
                :id="`kernet-row-${data.item.id}-hapus-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button>
              <b-tooltip
                title="Hapus"
                :target="`kernet-row-${data.item.id}-hapus-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalKernet"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <AddKernetContent
      v-model="addKernetSidebar"
      :kernet="dataEditKernet"
      @kernet-sidebar-change="editKernet"
      @submit-data-kernet="submitData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BPagination, BTooltip, BButtonGroup,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import AddKernetContent from './Add.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BButtonGroup,
    BBadge,

    AddKernetContent,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'Nama Kernet',
          key: 'nama',
          sortable: true,
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'No Telepon',
          key: 'telp',
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      perPage: 5,
      totalKernet: 0,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      rowsData: [],
      searchTerm: '',
      statusOptions: [
        'Aktif',
        'Tidak Aktif',
      ],
      addKernetSidebar: false,
      dataEditKernet: {
        id: '1',
        name: 'Arjuna',
        email: 'arjuna@gmail.com',
        no_telp: '08233716284',
        status_kernet: 1,
        status: 1,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      if (this.statusFilter !== null) {
        const statusFilter = this.statusFilter === 'Aktif' ? 'aktif' : 'tidak_aktif'

        return this.rowsData.filter(data => data.status === statusFilter)
      }
      return this.rowsData
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // eslint-disable-next-line no-return-assign
      this.$http.get('/kernet-data').then(response => {
        this.rowsData = response.data.data

        this.totalKernet = response.data.data.length
      })
    },
    editKernet(data, value) {
      this.dataEditKernet = data
      this.addKernetSidebar = value
    },
    submitData(data) {
      if (data.id === null) {
        const formData = new FormData()
        formData.append('nama', data.nama)
        formData.append('email', data.email)
        formData.append('password', data.password)
        formData.append('telp', data.no_telp)
        formData.append('status', data.status)
        formData.append('foto', data.foto_kernet)

        this.$http.post('/kernet-add', formData).then(response => {
          this.getData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })
        })
      } else {
        const formData = new FormData()
        formData.append('nama', data.nama)
        formData.append('email', data.email)
        formData.append('password', data.password)
        formData.append('telp', data.no_telp)
        formData.append('status', data.status)
        formData.append('_method', 'PUT')
        formData.append('foto', data.foto_kernet ?? null)

        this.$http.post(`/kernet-update/${data.id}`, formData).then(response => {
          this.getData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })
        })
      }
      this.addKernetSidebar = false
    },
    removeData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/kernet-delete/${id}`).then(response => {
            if (response.data.status === true) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              setTimeout(() => {
                this.getData()
              }, 500)
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
